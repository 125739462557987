import * as React from "react";
import { IoPerson } from "react-icons/io5";
import { HiLocationMarker } from "react-icons/hi";
import { TbTransfer, TbTransferVertical } from "react-icons/tb";
import { getDynamicColors } from "../../utils/dynamic/colorUtils";
import CustomButton from "../../utils/constants/Button";
import { MessagesIcon } from "../../assets/ExportSvg";
import { TripType, useBookingContext } from "../../context/BookingContext";
import { useNavigate } from "react-router-dom";
import baseURL, { brandURL } from "../../api/ApiConfig";
import axios from "axios";
import CitySelect from "../../utils/constants/CitySelect";
import DateInput from "../../utils/constants/CustomDate";
import { formatDate } from "../../utils/date";

interface IBookTripProps { }

interface City {
	id: string;
	name: string;
	code: string | null;
}

interface CityOption {
	value: string;
	label: string;
}

const BookTrip: React.FunctionComponent<IBookTripProps> = () => {

	const dynamicColor = getDynamicColors();
	const [ridesAvailable, setRidesAvailable] = React.useState(0);
	const navigate = useNavigate();
	const {
		tripType,
		setTripType,
		numberOfTravelers,
		setNumberOfTravelers,
		bookAccommodation,
		setBookAccommodation,
	} = useBookingContext();

	const [fromCities, setFromCities] = React.useState<City[]>([]);
	const [toCities, setToCities] = React.useState<City[]>([]);

	const [selectedFromCity, setSelectedFromCity] =
		React.useState<CityOption | null>(null);

	const [selectedToCity, setSelectedToCity] = React.useState<CityOption | null>(
		null
	);
	const [departureDate, setDepartureDate] = React.useState<Date | null>(null);
	const [returnDate, setReturnDate] = React.useState<Date | null>(null);

	React.useEffect(() => {
		const fetchData = async () => {
			let config = {
				method: "get",
				maxBodyLength: Infinity,
				url: `${baseURL}/v1/intercity/connections/routes`,
				headers: {},
			};

			axios
				.request(config)
				.then((response) => {
					console.log("all routes: ", response.data.data);
					const routesResult = response.data.data;

					// console.log("here and there", routesResult)
					// setFromCities(routesResult.from_cities);
					// setToCities(routesResult.to_cities);
				})
				.catch((error) => {
					console.log(error);
				});
		};

		fetchData();
	}, []);

	React.useEffect(() => {
		const fetchData = async () => {
			try {
				const fromCityParam = selectedFromCity
					? `&from_city=${encodeURIComponent(selectedFromCity.label)}`
					: "";
				const toCityParam = selectedToCity
					? `&to_city=${encodeURIComponent(selectedToCity.label)}`
					: "";
				const departureDateParam = departureDate
					? `&departure_date=${formatDate(departureDate)}`
					: "";
				const brand = brandURL ? `&brand_url=${brandURL}` : "";

				const searchURL = `${baseURL}/v1/intercity/connections/find?per_page=10&page=1${fromCityParam}${toCityParam}${departureDateParam}${brand}`;
				// const searchURL = `${baseURL}/v1/intercity/connections/find?per_page=2&page=1`;

				const response = await axios.get(searchURL);
				// console.log("data heremm:", response.data.data);
				const available = response.data.meta.total;
				// setRidesAvailable(available);

				setRidesAvailable(response.data.data.length);

				const routesResult = response.data.data;
				const fromCities: any[] = Array.from(
					new Map(
						routesResult.map((ride: any) => [
							ride.ride_meta.ride_departure_location.city.name,
							ride.ride_meta.ride_departure_location.city,
						])
					).values()
				);				

				const toCities: any[] = Array.from(
					new Map(
						routesResult.map((ride: any) => [
							ride.ride_meta.ride_destination.location.city.name,
							ride.ride_meta.ride_destination.location.city,
						])
					).values()
				);				

				setFromCities(fromCities);
				setToCities(toCities);
			} catch (error) {
				console.error("Error fetching data:", error);
			}
		};

		fetchData();
	}, [selectedFromCity, selectedToCity, departureDate]);

	const handleTripTypeChange = (selectedType: TripType) => {
		setTripType(selectedType);
	};

	const handleNumberOfTravelersChange = (
		event: React.ChangeEvent<HTMLSelectElement>
	) => {
		setNumberOfTravelers(Number(event.target.value));
	};
	const handleToggle = () => {
		setBookAccommodation(!bookAccommodation);
	};
	const handleFromCitySelect = (selectedCity: CityOption | null) => {
		setSelectedFromCity(selectedCity);
	};

	const handleToCitySelect = (selectedCity: CityOption | null) => {
		setSelectedToCity(selectedCity);
	};

	const handleDepatureDateChange = (date: Date | null) => {
		setDepartureDate(date);
	};
	const handleReturnDateChange = (date: Date | null) => {
		setReturnDate(date);
	};

	const handleBookForm = (e: React.FormEvent) => {
		e.preventDefault();
		const fromCityParam = selectedFromCity
			? `&from_city=${encodeURIComponent(selectedFromCity?.label)}`
			: "";
		const toCityParam = selectedToCity
			? `&to_city=${encodeURIComponent(selectedToCity?.label)}`
			: "";
		const departureDateParam = departureDate
			? `&departure_date=${formatDate(departureDate)}`
			: "";
		const returnDateParam = returnDate
			? `&return_date=${formatDate(returnDate)}`
			: "";
		const passengers = numberOfTravelers ? `&pax=${numberOfTravelers}` : "";
		const brand = brandURL ? `&brand_url=${brandURL}` : "";

		navigate(
			`/select-station?${fromCityParam || ""}${toCityParam || ""}${departureDateParam ?? ""
			}${returnDateParam ?? ""}${brand}${passengers ?? ""}`
		);
	};

	return (
		<div className="w-full">
			<div className="flex lg:space-x-6 gap-3">
				<div className="hidden lg:flex gap-2">
					<button
						className={`px-3 py-2 rounded border-0 text-sm ${tripType === TripType.OneWay
							? "bg-blue-700 text-white"
							: "bg-gray-50 text-gray-500"
							}`}
						onClick={() => handleTripTypeChange(TripType.OneWay)}>
						One Way
					</button>

					<button
						className={`px-3 py-2 rounded border-0 text-sm ${tripType === TripType.RoundTrip
							? "bg-blue-700 text-white"
							: "bg-gray-50 text-gray-500"
							}`}
						onClick={() => handleTripTypeChange(TripType.RoundTrip)}>
						Round Trip
					</button>
					{/* <button
						className={`px-3 py-2 rounded border-0 text-sm ${
							tripType === TripType.RoundTrip
								? "bg-blue-700 text-white"
								: "bg-gray-50 text-gray-500"
						}`}
						onClick={() => handleTripTypeChange(TripType.RoundTrip)}>
						Round Trip
					</button> */}
				</div>

				<div className="relative lg:hidden">
					<select
						className="w-full pl-2 pr-8 bg-transparent border border-transparent p-2 rounded appearance-none ring-0 ring-transparent focus:outline-none focus:border-transparent"
						value={tripType}
						onChange={(e) => handleTripTypeChange(e.target.value as TripType)}>
						{Object.values(TripType).map((type) => (
							<option key={type} value={type} className="text-gray-900">
								{type}
							</option>
						))}
					</select>
					<div className="absolute inset-y-0 right-0 pr-2 flex items-center pointer-events-none">
						{/* <IoMdArrowDropdown className="text-gray-500" /> */}
					</div>
				</div>

				<div className="relative flex items-center">
					<div className="absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none">
						<IoPerson className="text-gray-500" />
					</div>
					<select
						className="w-full pl-8 pr-8 bg-transparent border border-transparent p-2 rounded appearance-none focus:outline-none focus:border-transparent"
						value={numberOfTravelers}
						onChange={handleNumberOfTravelersChange}
					>
						{[1, 2, 3, 4, 5].map((number) => (
							<option key={number} value={number} className="text-gray-900">
								{number} {number === 1 ? "Traveller" : "Travellers"}
							</option>
						))}
					</select>
					<div className="absolute inset-y-0 right-0 pr-2 flex items-center pointer-events-none">
						{/* <IoMdArrowDropdown className="text-gray-500" /> */}
					</div>
				</div>
			</div>

			<form
				onSubmit={handleBookForm}
				autoComplete="off"
				className="w-full space-y-5 mt-5">
				<div className="lg:relative space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-8">
					<CitySelect
						selectedCity={selectedFromCity}
						setSelectedCity={setSelectedFromCity}
						cities={fromCities}
						onSelectCity={handleFromCitySelect}
						label="Travel from"
						prefixIcon={<HiLocationMarker />}
						onBlur={() => { }}						
					/>

					<div className="mx-auto w-6 h-6 lg:w-5 lg:h-5 lg:absolute top-[40%] lg:top-[60%] left-[45%] lg:left-[48%] bg-orange-500 flex items-center justify-center text-white rounded-full text-sm">
						<TbTransfer className="hidden lg:block" />
						<TbTransferVertical className="lg:hidden" />
					</div>

					<CitySelect
						selectedCity={selectedToCity}
						setSelectedCity={setSelectedToCity}
						cities={toCities}
						onSelectCity={handleToCitySelect}
						label="Travel to"
						prefixIcon={<HiLocationMarker />}
						onBlur={() => { }}
					/>
				</div>

				<div className="space-y-5 lg:space-y-0 lg:grid lg:grid-cols-2 lg:gap-8">
					<DateInput
						label="Departure date"
						selectedDate={departureDate}
						handleDateChange={handleDepatureDateChange}
						disabled={false}
						onBlur={() => { }}
					/>
					<DateInput
						label="Return date"
						selectedDate={returnDate}
						handleDateChange={handleReturnDateChange}
						// disabled={true}
						disabled={tripType === TripType.OneWay}
						onBlur={() => { }}
					/>
				</div>

				<div className="hidden items-center">
					<div className="relative inline-block w-10 mr-2 align-middle select-none transition duration-200 ease-in">
						<input
							type="checkbox"
							name="toggle"
							checked={bookAccommodation}
							onChange={handleToggle}
							className={`toggle-checkbox ${bookAccommodation ? "right-1" : "left-1"
								} absolute top-[16%] block w-4 h-4 rounded-full border-0 bg-white appearance-none cursor-pointer`}
						/>
						<label
							htmlFor="toggle"
							onClick={handleToggle}
							style={{
								background: bookAccommodation
									? dynamicColor.primary
									: "#6B7280",
							}}
							className={`toggle-label block overflow-hidden h-6 rounded-full cursor-pointer`}></label>
					</div>
					<p className="text-gray-500 text-sm leading-[18px] font-normal ">
						Book accommodation with hotel.ng
					</p>
				</div>

				<CustomButton
					small={false}
					onClick={() => { }}
					type="submit"
					text={`View ${ridesAvailable} rides available`}
				/>
				<div className="hidden items-center justify-center">
					<button
						type="button"
						className="px-2.5 py-0.5 bg-gray-100 rounded-md justify-center items-center gap-1 inline-flex">
						<MessagesIcon />
						<p className="text-gray-900 text-sm">
							Can&apos;t find your ride? Tell us here
						</p>
					</button>
				</div>
			</form>
		</div>
	);
};

export default BookTrip;
