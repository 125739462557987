import * as React from "react";
import { MdAccessTimeFilled } from "react-icons/md";
// import {
// 	ACIcon,
// 	BatteryIcon,
// 	BusIcon,
// 	DrinkIcon,
// 	SoundIcon,
// 	WifiIcon,
// } from "../../assets/ExportSvg";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Pagination, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import CurrencyFormat from "../../utils/constants/CurrencyFormat";
import TimeConversion from "../../utils/constants/TimeConversion";
import cheapestBadge from '../../assets/cheapest-badge.svg';
import cheapestBadgeMd from '../../assets/cheapest-badge-md.png';
import clock from '../../assets/clock-dark.svg';
import links from '../../assets/link-points.svg';
import arrowRight from '../../assets/arrow-right-md.svg';
import seats from '../../assets/passenger-seats.svg';
import { IoChevronDown } from "react-icons/io5";

interface IBookTripResultTemplateProps {
	data: {
		ride_departure_id: string;
		ride_meta: {
			ride_meta_id: string;
			ride_destination: {
				ride_destination_id: string;
				amount: number;
				arrival_time: string;
				discount: {
					ride_discount_amount: number;
					date_discount_amount: number;
				};
				id: string;
				amount_with_discount: number;
				location: {
					name: string;
					description: string | null;
					address: string | null;
					latitude: number | null;
					longitude: number | null;
					what3word: string | null;
					city: {
						id: string;
						name: string;
						code: string | null;
					};
				};
			};
			departure_time: string;
			comment: string | null;
			business: {
				id: string;
				business_name: string;
				business_email: string;
				phone_number: string;
				address: string;
				postal_code: string | null;
				api_key: string | null;
				abbr_code: string | null;
				logo_url: string;
				is_verified: boolean;
				status: string;
				longitude: number | null;
				latitude: number | null;
				what3word: string | null;
			};
			brand: {
				id: string;
				brand_name: string;
				rating: number;
				status: string;
				logo_url: string;
				url: string;
				customers: {
					bookings: number;
					parcels: number;
				};
				brand_gallery: {
					brand_gallery_id: string;
					image_url: string;
				}[];
			};
			currency: {
				id: string;
				name: string;
				symbol: string;
				code: string;
			};
			vehicle_type: {
				id: string;
				name: string;
				category: string;
				row_col: [number, number];
				cordinates: [number, number][];
				status: string;
				capacity: number;
			};
			ride_departure_location: {
				name: string;
				description: string | null;
				address: string;
				latitude: number;
				longitude: number;
				what3word: string;
				city: {
					id: string;
					name: string;
					code: string | null;
				};
			};
			departure_location: {
				name: string;
				description: string | null;
				address: string;
				latitude: number;
				longitude: number;
				what3word: string;
				city: {
					id: string;
					name: string;
					code: string | null;
				};
			};
			ride_destinations: {
				ride_destination_id: string;
				amount: number;
				arrival_time: string;
				discount: {
					ride_discount_amount: number;
					date_discount_amount: number;
				};
				id: string;
				amount_with_discount: number;
				location: {
					name: string;
					description: string | null;
					address: string | null;
					latitude: number | null;
					longitude: number | null;
					what3word: string | null;
					city: {
						id: string;
						name: string;
						code: string | null;
					};
				};
			}[];
		};
		departure_date: string;
		no_of_seats: number;
		seats: {
			id: string;
			ride_seat_id: string;
			x: number;
			y: number;
			seat_no: number;
			is_available: boolean;
			is_reserved: boolean;
		}[];
		dispatch_status: string;
		id: string;
		ride_code: string | null;
	};
	pax: number;
}


const BookTripResultTemplate: React.FunctionComponent<
	IBookTripResultTemplateProps
> = (props) => {
	const { data, pax } = props;

	// React.useEffect(() => {
	// 	window.scrollTo(0, 0);
	// }, []);

	const availableSeats = data.seats.filter((seat) => seat.is_available);
	const totalAvailableSeats = availableSeats.length;
	const handleBuyNowClick = () => {
		// Save data to sessionStorage
		sessionStorage.setItem("selectedTripData", JSON.stringify(data));
	};
	return (
		<div className=" relative  showNavigation w-full px-[7px] py-[4px]  lg:py-[46px] lg:px-[54px] bg-white rounded-lg border border-[#E5E7EB] ">
			<div className="grid lg:grid-cols-5 grid-cols-5">
				<div className="col-span-2 lg:col-span-1 lg:h-full w-full ">
					<div className="flex flex-col justify-between h-full">
						<div>
							<p className="text-t40Black text-base lg:text-lg font-semibold">
								{data.ride_meta.ride_departure_location.city.name}
							</p>

							<p className="pt-[7px] text-xs lg:text-sm font-medium text-gray-500">
								{data.ride_meta.ride_departure_location.name}
							</p>
						</div>

						<div className="flex items-center space-x-6">
							<p className="flex items-center space-x-1">
								<img src={clock} alt="time of departure" />
								<span className="text-t40Black block font-medium text-sm ">
									<TimeConversion timeString={data.ride_meta.departure_time} />
								</span>
							</p>

							<p className="lg:flex hidden items-center space-x-1">
								<img className="w-3 h-3 lg:w-[14px] lg:h-[14px]" src={seats} alt="available passenger seats" />
								<span className="text-orange-400 block font-medium text-[12px] lg:text-sm ">
									{totalAvailableSeats} {totalAvailableSeats > 1 ? "seats" : "seat"}{" "}
									left
								</span>
							</p>
						</div>

						<button className="text-[10px] w-fit lg:text-xs flex lg:hidden items-center space-x-1 py-[4px] px-[3x] rounded-[6px] font-medium text-gray-500 bg-[#F3F4F6]">
							<span className="block">
								Amenities
							</span>

							<IoChevronDown className="w-3 h-3 lg:w-[14px] lg:h-[14px]" />
						</button>


					</div>
				</div>

				<div className="lg:hidden flex items-center justify-center col-span-1">
					<img src={arrowRight} alt="solid arrow right" />
				</div>

				{/* Details */}
				<div className="lg:col-span-3 col-span-2  py-4 px-4 lg:px-10 h-full">
					<div className="lg:grid grid-cols-2 lg:space-y-0 space-y-3 gap-x-10">
						<div className="hidden lg:flex justify-center items-center h-full">
							<img src={links} alt="origin to destination points" />
						</div>

						<div className="h-full flex flex-col lg:items-start items-center justify-between">
							<div>
								<p className="text-t40Black text-base lg:text-left text-center lg:text-lg font-semibold">
									{data.ride_meta.ride_destination.location.city.name}
								</p>

								<p className="pt-[2px] text-sm lg:text-left text-center lg:text-xs font-medium text-gray-500">
									{data.ride_meta.ride_destination.location.name}
								</p>
							</div>

							<div className="mt-4">
								<div className="h-full lg:hidden flex flex-col justify-between">
									<div>
										<p className="text-orange-400 text-xs font-bold line-through text-center">
											{data.ride_meta.currency.symbol}
											<CurrencyFormat amount={data.ride_meta.ride_destination.amount} />
										</p>
										<p className="text-t40Black text-center text-xl font-semibold pt-0.5">
											{data.ride_meta.currency.symbol}
											<CurrencyFormat
												amount={data.ride_meta.ride_destination.amount_with_discount}
											/>
										</p>
									</div>
									
								</div>

								<button className="hidden text-[10px] lg:text-xs lg:flex items-center space-x-1 py-[4px] px-[3x] rounded-[6px] font-medium text-gray-500 bg-[#F3F4F6]">
									<span className="block">
										Amenities
									</span>

									<IoChevronDown className="w-3 h-3 lg:w-[14px] lg:h-[14px]" />
								</button>
							</div>
						</div>
					</div>
				</div>

				{/* Price and Button */}
				<div className="lg:block hidden col-span-1 ">
					<div className="h-full flex flex-col justify-between">
						<div>
							<p className="text-orange-400 text-xs font-bold line-through text-center">
								{data.ride_meta.currency.symbol}
								<CurrencyFormat amount={data.ride_meta.ride_destination.amount} />
							</p>
							<p className="text-t40Black text-center text-xl font-semibold pt-0.5">
								{data.ride_meta.currency.symbol}
								<CurrencyFormat
									amount={data.ride_meta.ride_destination.amount_with_discount}
								/>
							</p>
						</div>

						<div className="flex justify-center items-center">
							<Link to={`/select-station/${data.id}`} onClick={handleBuyNowClick}>
								<button className="lg:px-[18px] lg:py-[8px] px-3 py-[8px] rounded-[8px] text-white bg-primary-700 font-medium text-xs lg:text-sm">
									Buy ticket
								</button>
							</Link>
						</div>
					</div>
				</div>
			</div>

			{/* Price and Button Md*/}
			<div className="lg:hidden block col-span-1 ">
				<div className="h-full flex flex-col justify-between">


					<div className="flex justify-center items-center">
						<Link to={`/select-station/${data.id}`} onClick={handleBuyNowClick}>
							<button className="lg:px-[18px] lg:py-[8px] px-3 py-[8px] rounded-[8px] text-white bg-primary-700 font-medium text-xs lg:text-sm">
								Buy ticket
							</button>
						</Link>
					</div>
				</div>
			</div>

			{/* badge */}
			{/* <img className="lg:block hidden absolute top-0 right-0" src={cheapestBadge} alt="cheapest price tag" /> */}
			{/* <img className="lg:hidden block absolute top-0 right-0" src={cheapestBadgeMd} alt="cheapest price tag" /> */}
		</div>
	);
};

export default BookTripResultTemplate;
